import React from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import "./Footer.css";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <>
            <footer>
                <p>
                    <a href="/contact-us">{t("Contact Us")}</a> {/* | <a href="#">{t("Terms of Service")}</a> */}| <a href="https://ptre.chez.gg/" target="_BLANK" rel="noopener noreferrer">{t("PTREDesc")}</a> | <a href="https://status.ogame.tools" target="_BLANK" rel="noopener noreferrer">{t("Status")}</a>
                </p>
                <p>{ReactHtmlParser(t("Copyright"))}</p>
            </footer>
            {/* <div id="cky-consent" class="cky-consent-container" style="bottom: 10px; opacity: 1;">
                <div class="cky-consent-bar">
                    <div class="cky-notice">
                        <div class="cky-title">We value your privacy</div>
                        <div class="cky-notice-group">
                            <div class="cky-notice-des">
                                <p>
                                    We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.&nbsp;
                                    <a href="/agreement" class="cky-policy" target="_blank" rel="noopener">User Agreement and Privacy</a>
                                </p>
                            </div>
                            <div class="cky-notice-btn-wrapper">
                                <button class="cky-btn cky-btn-reject" onclick="cky_accept(1);">Reject All</button>
                                <button class="cky-btn cky-btn-accept" onclick="cky_accept(2);">Accept All</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}