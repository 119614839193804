import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import { CircleLoader } from 'react-spinners';

import axios from 'axios';
import config from '../../config.json';

import "./PositionFinder.css";

export default function PositionFinder() {
    const { t } = useTranslation();

    const [loadingPositions, setLoadingPositions] = useState(true);
    const [freePositions, setFreePositions] = useState([]);
    const [freePositionsCount, setFreePositionsCount] = useState(0);
    const [maxGalaxies, setMaxGalaxies] = useState(0);
    const [communities, setCommunities] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [httpRequestError, setHttpRequestError] = useState("");

    const getDefaultSettings = () => ({
        community: "",
        galaxy: "1",
        position: "8",
        selectedServer: "",
        sortOrder: "fullness",
        sortDirection: "asc",
    });
    
    const initializeSettings = () => {
        const defaultSettings = getDefaultSettings();
        const savedSettings = JSON.parse(localStorage.getItem('positionFinderData')) || {};
    
        // Merge saved settings with defaults
        const mergedSettings = { ...defaultSettings, ...savedSettings };
    
        // Save back to localStorage to ensure defaults are stored
        localStorage.setItem('positionFinderData', JSON.stringify(mergedSettings));
    
        return mergedSettings;
    };

    const [SettingsData, setSettingsData] = useState(initializeSettings());

    // Handle changes in community or position selection
    const handleCommunityChange = (community) => {
        const newSettingsData = {
            ...SettingsData,
            selectedServer: community && communities[community] && communities[community].length > 0 ? String(communities[community][0].number) : "",
            community: community,
            galaxy: "1",
        };
        localStorage.setItem('positionFinderData', JSON.stringify(newSettingsData));
        setSettingsData(newSettingsData);
    };

    const handlePositionChange = (position) => {
        const newSettingsData = {
            ...SettingsData,
            position: position
        };
        localStorage.setItem('positionFinderData', JSON.stringify(newSettingsData));
        setSettingsData(newSettingsData);
    };

    const handleGalaxyChange = (galaxy) => {
        const newSettingsData = {
            ...SettingsData,
            galaxy: galaxy
        };
        localStorage.setItem('positionFinderData', JSON.stringify(newSettingsData));
        setCurrentPage(1);
        setSettingsData(newSettingsData);
    };

    const handleServerChange = (server) => {
        const newSettingsData = {
            ...SettingsData,
            selectedServer: server,
            galaxy: "1",
        };
        localStorage.setItem('positionFinderData', JSON.stringify(newSettingsData));
        setSettingsData(newSettingsData);
    };

    const updateSortOrder = (column) => {
        setSettingsData((prevSettings) => {
            const isSameColumn = prevSettings.sortOrder === column;
            const newSortDirection = isSameColumn
                ? (prevSettings.sortDirection === "asc" ? "desc" : "asc")
                : "asc"; 
    
            const updatedSettings = {
                ...prevSettings,
                sortOrder: column,
                sortDirection: newSortDirection,
            };
    
            localStorage.setItem("positionFinderData", JSON.stringify(updatedSettings));
    
            return updatedSettings;
        });
    };    

    useEffect(() => {
        // Fetch communities from API
        let apiUrl = config.develop ? config.develop_api : config.api;
        axios.get(`${apiUrl}/get-communities`)
            .then(response => {
                setCommunities(response.data.communities);
            })
            .catch(error => {
                setHttpRequestError(t("unknown_error_contact_admins"));
            });
    }, []);

    useEffect(() => {
        if (!SettingsData.community || !SettingsData.selectedServer) return;

        let apiUrl = config.develop ? config.develop_api : config.api;
    
        const request = axios.post(`${apiUrl}/position-finder`, {
            options: {
                page: currentPage,
                community: SettingsData.community,
                galaxy: SettingsData.galaxy,
                position: SettingsData.position,
                server: SettingsData.selectedServer,
                sorting: {
                    order: SettingsData.sortOrder,
                    direction: SettingsData.sortDirection
                }
            }
        });

        setLoadingPositions(true);

        request
            .then(function (response) {
                const { positions, current_page, positions_count, max_page, max_galaxies } = response.data;

                setFreePositions(positions || []);
                setFreePositionsCount(positions_count || 0);
                setMaxGalaxies(max_galaxies || 0)
                setCurrentPage(current_page || 1);
                setMaxPage(max_page || 1);
                setLoadingPositions(false);
            })
            .catch(function (error) {
                setHttpRequestError(t("unknown_error_contact_admins"))
                setLoadingPositions(false);
            });
    }, [currentPage, SettingsData]);

    return (
        <>
            <Helmet>
                <title>{t("TitlePositionFinder")}</title>
            </Helmet>
            <div id="report-header">
                <h2 className="subtitle">{t("TitlePositionFinder")}</h2>
            </div>

            <div className={`filters-panel`}>
                <div className="options-panel-header">
                    <span className="options-title">{t('Filters')}</span>
                </div>
                <div className="options-panel-content">
                    <div id="options-filters">
                        <>
                        <div id="filter-position-community-wrapper">
                            {/* Community & Server Filters */}
                            <div id="filter-community-wrapper">
                                <div id="filter-community">
                                    <img
                                        style={{ height: '24px', display: 'block', margin: 'auto' }}
                                        src={SettingsData.community ? `/assets/img/flags/${SettingsData.community}.webp` : '/assets/img/flags/world.webp'}
                                        alt=""
                                    />
                                    <ul>
                                        <li key="world" onClick={() => handleCommunityChange("")}>
                                            <img style={{ height: '24px' }} src={`/assets/img/flags/world.webp`} alt="world" />
                                        </li>
                                        {Object.keys(communities).map(languageCode => (
                                            <li key={languageCode} onClick={() => handleCommunityChange(languageCode)}>
                                                <img style={{ height: '24px' }} src={`/assets/img/flags/${languageCode}.webp`} alt={languageCode} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {SettingsData.community && communities[SettingsData.community] && (
                                    <div id="filter-server-wrapper">
                                        <select id="server-select" value={SettingsData.selectedServer || ""} onChange={(e) => handleServerChange(e.target.value)}>
                                            <option value="" disabled>Select a server</option>
                                            {communities[SettingsData.community].map(server => (
                                                <option key={server.number} value={server.number}>
                                                    {server.number} - {server.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>

                            {/* Galaxy & Position Filters */}
                            {SettingsData.community && communities[SettingsData.community] && (
                            <div id="filter-position-wrapper">
                                <label htmlFor="galaxy-select">{t("Select Galaxy")}</label>
                                <select
                                    id="galaxy-select"
                                    value={SettingsData.galaxy || "1"}
                                    onChange={(e) => handleGalaxyChange(e.target.value)}
                                >
                                    {[...Array(maxGalaxies).keys()].map(i => (
                                        <option key={i+1} value={i+1}>{i+1}</option>
                                    ))}
                                </select>
                                <label htmlFor="position-select">{t("Select Position")}</label>
                                <select
                                    id="position-select"
                                    value={SettingsData.position || "8"}
                                    onChange={(e) => handlePositionChange(e.target.value)}
                                >
                                    {[...Array(15).keys()].map(i => (
                                        <option key={i+1} value={i+1}>{i+1}</option>
                                    ))}
                                </select>
                            </div>
                            )}
                        </div>

                        </>
                    </div>
                </div>
            </div>

            <table class="tablesorter ally_ranks tabstatistica" style={{marginBottom: '10px', marginTop: '20px', textAlign: 'right'}}>
                <tbody>
                    <tr class="classificabarra ">
                        <th></th>
                        <th onClick={() => updateSortOrder("system")} style={{ cursor: "pointer" }}>
                            {t("System")}
                            <span>
                                {SettingsData.sortOrder === "system" && 
                                    (SettingsData.sortDirection === "asc" ? " 🔼" : " 🔽")}
                            </span>
                        </th>
                        <th onClick={() => updateSortOrder("fullness")} style={{ cursor: "pointer" }}>
                            {t("Fullness")}
                            <span>
                                {SettingsData.sortOrder === "fullness" && 
                                    (SettingsData.sortDirection === "asc" ? " 🔼" : " 🔽")}
                            </span>
                        </th>
                        <th>{t("Top Player")}</th>
                        <th>{t("Top Rank")}</th>
                    </tr>
                    {loadingPositions ? (
                        <tr>
                            <td colspan="5" style={{ textAlign: 'center' }}>
                                {(!SettingsData.community || !SettingsData.selectedServer) ? (
                                    <div>
                                        <CircleLoader color={'#73cdf0e6'} loading={loadingPositions} size={50} cssOverride={{ display: 'inline-table' }} />
                                        <p style={{ color: '#ff6347' }}>{t("Please select the community and the server in the above filters to load the positions")}</p>
                                    </div>
                                ) : (
                                    <CircleLoader color={'#73cdf0e6'} loading={loadingPositions} size={50} cssOverride={{ display: 'inline-table' }} />
                                )}
                            </td>
                        </tr>
                    ) : (
                        <>
                        {freePositions.length > 0 ? (
                            <>
                            {freePositions.map((freePosition, index) => (
                                <tr key={index} className="classificabarra line">
                                    <td></td>
                                   <td><span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>{freePosition.system_key}</span></td>
                                   <td><span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>{freePosition.fullness}</span></td>
                                   <td>
                                    <div className="barracla"></div>
                                    <span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>{freePosition.top_player}</span>
                                   </td>

                                   <td>
                                        {["1", "2", "3"].includes(freePosition.top_rank) ? (
                                            <span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>
                                            <img
                                                src={`/assets/img/trophees/${freePosition.top_rank}trof.webp`}
                                                alt={`Trophy for rank ${freePosition.top_rank}`}
                                                style={{ height: '16px', display: 'block', margin: 'auto' }}
                                            />
                                            </span>
                                        ) : (
                                            <span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>
                                            #{freePosition.top_rank}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </>
                        ) : (
                            <>
                            {httpRequestError !== "" ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center' }}>
                                    {httpRequestError}
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center' }}>
                                    {t("No free positions available")}
                                    </td>
                                </tr>
                            )}
                            </>
                        )}
                        </>
                    )}
                </tbody>
            </table>
            {httpRequestError === "" && (
                <div class="tablesorter pagination" style={{marginTop: '10px', textAlign: 'right'}}>
                    <span style={{ marginRight: '10px', color: '#c0c7d2' }}>{`${25*(currentPage-1)+1}-${(25*(currentPage-1))+25} of ${freePositionsCount}`}</span>
                    <button style={{ marginRight: '5px' }} disabled={currentPage === 1 || loadingPositions} onClick={() => setCurrentPage(currentPage - 1)}>{t("Previous")}</button>
                    <button style={{ marginRight: '5px' }} disabled={currentPage === maxPage || loadingPositions} onClick={() => setCurrentPage(currentPage + 1)}>{t("Next")}</button>
                </div>
            )}
        </>
    )
}